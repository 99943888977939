
import { Vue } from "vue-class-component";
// Import the jsPDF library
import jsPDF from "jspdf";
import axios from "axios";
import EN from "../assets/languages/EN.json";
import KA from "../assets/languages/KA.json";
import PRICES from "../assets/prices/prices.json";
let chosenProduct: number;
let imgAsset: string;
let formattedDate: string;
const discountRate = 30;

export default class BuyView extends Vue {
  public radioCheck() {
    const radioInputs = document.querySelectorAll<HTMLInputElement>(
      'input[name="product"]'
    );
    radioInputs.forEach((radioInput) => {
      if (radioInput.checked) {
        chosenProduct = parseInt(radioInput.value) - 1;
      }
    });
  }

  public async onSubmitPress() {
    this.getToday();
    const recipientNameElement = document.getElementById(
      "first-name"
    ) as HTMLInputElement;
    const recipientName = recipientNameElement.value;
    const recipientLastNameElement = document.getElementById(
      "last-name"
    ) as HTMLInputElement;
    const recipientLastName = recipientLastNameElement.value;
    const recipientIDElement = document.getElementById(
      "id-number"
    ) as HTMLInputElement;
    const recipientID = recipientIDElement.value;
    const recipientMailElement = document.getElementById(
      "e-mail"
    ) as HTMLInputElement;
    const recipientMail = recipientMailElement.value;

    const invoiceID = (Math.random() * 100000).toFixed(0);

    // Create a new PDF instance
    const pdf = new jsPDF();
    // Set font size and style
    pdf.setFontSize(16);
    pdf.setFont("courier", "normal", "700");
    pdf.setFillColor(0, 0, 0);
    pdf.rect(5, 5, 200, 287);
    pdf.setTextColor(0, 0, 0);

    // Add content to the PDF
    pdf.addImage(imgAsset, 90, 10, 30, 30);
    pdf.text("Payment Invoice For Gold's Gym Membership", 10, 60);
    pdf.text("Product Name: " + names[chosenProduct], 10, 75);
    pdf.text("Invoice Number:" + invoiceID, 10, 90);
    pdf.text(`Date: ${formattedDate}`, 10, 105);
    pdf.text("Recipient: " + recipientName + " " + recipientLastName, 10, 120);
    pdf.text("Recipient ID: " + recipientID, 10, 135);
    if (
      (await axios.get("https://api.goldsgym.ge/is-black-friday.php")).data
        .result
    ) {
      pdf.text(
        "Amount: " +
          (
            (PRICES.OriginalPrice[chosenProduct] * (100 - discountRate)) /
            100
          ).toFixed(2) +
          " GEL",
        10,
        150
      );
    } else {
      pdf.text(
        "Amount: " + PRICES.OriginalPrice[chosenProduct] + " GEL",
        10,
        150
      );
    }
    pdf.text("Reciever IBAN: " + "GE60BG0000000533885111", 10, 165);
    pdf.text(
      "Comment: " + invoiceID + " - " + recipientName + " " + recipientLastName,
      // " - " +
      // recipientID,
      10,
      180
    );

    // Save the PDF
    if (
      recipientName != null &&
      recipientLastName != null &&
      chosenProduct != null &&
      recipientMail.includes(".") &&
      recipientMail.includes("@")
    ) {
      pdf.save("golds-gym-invoice_" + invoiceID + ".pdf");
      this.sendEmailWithAttachment(pdf, recipientMail, invoiceID).then(() => {
        // Refresh the page
        location.reload();
      });
    }
  }

  public getToday() {
    // GET DATE
    // Get the current date
    const currentDate = new Date();
    // Extract date components
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");
    // Create the formatted date string
    formattedDate = `${year}-${month}-${day}`;
  }

  private async sendEmailWithAttachment(
    pdf: jsPDF,
    mailTo: string,
    invoiceN: string
  ) {
    try {
      const pdfBlob = new Blob([pdf.output("arraybuffer")], {
        type: "application/pdf",
      });
      const formData = new FormData();
      formData.append("to", mailTo);
      formData.append("subject", "Invoice Number - " + invoiceN);
      formData.append(
        "message",
        "This Is Your Invoice For Purchase You want to make at our gym. Gold's Gym Tbilisi"
      );
      if (pdfBlob) {
        formData.append("pdfFile", pdfBlob);
      }

      const response = await axios.post(
        "https://api.goldsgym.ge/send-email.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async mounted() {
    this.$nextTick(() => {
      if (localStorage.getItem("currentLanguage") == "EN") {
        let i = 0;
        EN.buyV.forEach((e) => {
          document.querySelectorAll(".buy-" + i).forEach((element) => {
            element.innerHTML = e;
          });
          i += 1;
        });
      } else if (localStorage.getItem("currentLanguage") == "KA") {
        let i = 0;
        KA.buyV.forEach((e) => {
          document.querySelectorAll(".buy-" + i).forEach((element) => {
            element.innerHTML = e;
          });
          i += 1;
        });
      }
    });
    for (let products = 0; products < PRICES.OriginalPrice.length; products++) {
      let productPriceHTML = PRICES.OriginalPrice[products].toString() + " ₾";
      if (
        (await axios.get("https://api.goldsgym.ge/is-black-friday.php")).data
          .result
      ) {
        productPriceHTML =
          "<span class='price-invalid'>" + productPriceHTML + "</span>";
        productPriceHTML +=
          "<span class='discount-percent'>-" + discountRate + "%</span>";
        productPriceHTML +=
          "<span class='discounted-price'>" +
          (
            (PRICES.OriginalPrice[products] * (100 - discountRate)) /
            100
          ).toFixed(2) +
          " ₾" +
          "</span>";
      }
      document.querySelector(".product-price-" + products)!.innerHTML =
        productPriceHTML;
    }
  }
}

const names = [
  "Unlimited Membership For One Month",
  "Unlimited Membership For Three Months",
  "Unlimited Membership For Six Months",
  "Unlimited Membership For One Year",
  "Personal Trainer (1 Visit)",
  "Personal Trainer (12 visits)",
  "VIP",
  "Boxing (12 Visits)",
];

fetch("./media/invoiceImg.txt")
  .then((response) => response.text())
  .then((textContent) => {
    imgAsset = textContent; // The content of the text file as a string
  });
